@tailwind base;
@tailwind components;
@tailwind utilities;



@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slide-up {
  animation: slide-up 1.0s ease-out;
}
